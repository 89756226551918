.nav-bar {
  //position: absolute;
  //z-index: 1;

  padding: 30px;
  font-size: 24px;
  background-color: white;
  flex-direction: row;
  display: flex;

  font-style: normal;
  font-weight: normal;
  line-height: 30px;

  color: #000000;

  .nav-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .logo {
    flex-grow: 9;
    height: 30px;
  }

  .hive-text {
    position: relative;
    top: -5px;
    left: -15px;
  }

  .orange-hexagon {
    position: relative;
    top: 11px;
  }
  .white-hexagon {
    position: relative;
    left: -45px;
    top: 5px;
  }

  .sm-icon {
    height: 30px;
    margin: 25px 0;
  }
  .med-icon {
    max-height: 80px;
  }
}

