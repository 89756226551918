.title {
  margin: 50px;
  width: 495px;
  height: 241px;

  font-family: Lexend Deca, cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 80px;

}

.globe {
  position: absolute;
  z-index: -1;

  left: 500px;
  top: 50px;

  mix-blend-mode: multiply;
}

.white-ellipse {
  position: absolute;
  z-index: -1;

  left: 500px;
  top: 194px;

}

.grey-ellipse {
  position: absolute;
  z-index: -1;

  left: 500px;
  top: 99px;

}