@import 'assets/css/Fonts';

body {
  margin: 0;
  background: #F7F8FA;

  font-family: 'Lexend Deca', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
